var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container content-width terms-counter",class:{ en: _vm.$i18n.locale === 'en' }},[_c('div',{staticClass:"block"}),_c('div',{staticClass:"ts-measure ts-measure-center"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t("title")))])]),_c('div',{staticClass:"section"},[_c('h2',[_vm._v(_vm._s(_vm.$t("category.01")))]),_vm._l((['01', '02', '03']),function(item){return _c('Clause',{key:item,attrs:{"heading":_vm.$t(("clauses.clause" + item + ".heading")),"html":_vm.$t(("clauses.clause" + item + ".html"))}})})],2),_c('div',{staticClass:"section"},[_c('h2',[_vm._v(_vm._s(_vm.$t("category.02")))]),_vm._l(([
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16' ]),function(item){return _c('Clause',{key:item,attrs:{"heading":_vm.$t(("clauses.clause" + item + ".heading")),"html":_vm.$t(("clauses.clause" + item + ".html"))}})})],2),_c('div',{staticClass:"section"},[_c('h2',[_vm._v(_vm._s(_vm.$t("category.03")))]),_vm._l((['17', '18', '19', '20', '21', '22']),function(item){return _c('Clause',{key:item,attrs:{"heading":_vm.$t(("clauses.clause" + item + ".heading")),"html":_vm.$t(("clauses.clause" + item + ".html"))}})})],2),_c('div',{staticClass:"section"},[_c('h2',[_vm._v(_vm._s(_vm.$t("category.04")))]),_vm._l((['23']),function(item){return _c('Clause',{key:item,attrs:{"heading":_vm.$t(("clauses.clause" + item + ".heading")),"html":_vm.$t(("clauses.clause" + item + ".html"))}})})],2),_c('div',{staticClass:"section"},[_c('h2',[_vm._v(_vm._s(_vm.$t("category.05")))]),_vm._l((['24', '25']),function(item){return _c('Clause',{key:item,attrs:{"heading":_vm.$t(("clauses.clause" + item + ".heading")),"html":_vm.$t(("clauses.clause" + item + ".html"))}})})],2),_c('div',{staticClass:"section"},[_c('h2',[_vm._v(_vm._s(_vm.$t("category.06")))]),_vm._l((['26']),function(item){return _c('Clause',{key:item,attrs:{"heading":_vm.$t(("clauses.clause" + item + ".heading")),"html":_vm.$t(("clauses.clause" + item + ".html"))}})})],2),_c('div',{staticClass:"section"},[_c('h2',[_vm._v(_vm._s(_vm.$t("category.07")))]),_vm._l((['27', '28', '29', '30', '31', '32', '33']),function(item){return _c('Clause',{key:item,attrs:{"heading":_vm.$t(("clauses.clause" + item + ".heading")),"html":_vm.$t(("clauses.clause" + item + ".html"))}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }