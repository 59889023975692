<template>
    <div class="container content-width terms-counter" :class="{ en: $i18n.locale === 'en' }">
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>

        <div class="section">
            <h2>{{ $t("category.01") }}</h2>
            <Clause
                v-for="item in ['01', '02', '03']"
                :key="item"
                :heading="$t(`clauses.clause${item}.heading`)"
                :html="$t(`clauses.clause${item}.html`)"
            />
        </div>

        <div class="section">
            <h2>{{ $t("category.02") }}</h2>
            <Clause
                v-for="item in [
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                ]"
                :key="item"
                :heading="$t(`clauses.clause${item}.heading`)"
                :html="$t(`clauses.clause${item}.html`)"
            />
        </div>

        <div class="section">
            <h2>{{ $t("category.03") }}</h2>
            <Clause
                v-for="item in ['17', '18', '19', '20', '21', '22']"
                :key="item"
                :heading="$t(`clauses.clause${item}.heading`)"
                :html="$t(`clauses.clause${item}.html`)"
            />
        </div>
        <div class="section">
            <h2>{{ $t("category.04") }}</h2>
            <Clause
                v-for="item in ['23']"
                :key="item"
                :heading="$t(`clauses.clause${item}.heading`)"
                :html="$t(`clauses.clause${item}.html`)"
            />
        </div>
        <div class="section">
            <h2>{{ $t("category.05") }}</h2>
            <Clause
                v-for="item in ['24', '25']"
                :key="item"
                :heading="$t(`clauses.clause${item}.heading`)"
                :html="$t(`clauses.clause${item}.html`)"
            />
        </div>
        <div class="section">
            <h2>{{ $t("category.06") }}</h2>
            <Clause
                v-for="item in ['26']"
                :key="item"
                :heading="$t(`clauses.clause${item}.heading`)"
                :html="$t(`clauses.clause${item}.html`)"
            />
        </div>
        <div class="section">
            <h2>{{ $t("category.07") }}</h2>
            <Clause
                v-for="item in ['27', '28', '29', '30', '31', '32', '33']"
                :key="item"
                :heading="$t(`clauses.clause${item}.heading`)"
                :html="$t(`clauses.clause${item}.html`)"
            />
        </div>
    </div>
</template>
<script>
import Clause from "@/components/Clause"

export default {
    components: {
        Clause,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.terms.title"),
            description: this.$i18n.t("meta.terms.description"),
        })
    },
    data() {
        return {
            imageToggle: "balance",
        }
    },
    i18n: {
        messages: {
            en: {
                title: "Terms of Use",
                category: {
                    "01": "General Provisions",
                    "02": "Services",
                    "03": "Your Obligations",
                    "04": "Prohibited Acts",
                    "05": "Cancellation and Refund",
                    "06": "Limitation of Liability",
                    "07": "Miscellaneous",
                },
                clauses: {
                    clause01: {
                        heading: "Application of Terms of Use",
                        html: `
                    <p class="ts-quiet">These are the terms of use (“the Terms” or "Terms”) of the users for the supply of the Stork Mobile products and services (collectively, the “Services” or “Service”) provided ROKKODE INC. (“ROKKODE”, “we” or “us”) that are binding and govern the relationship between the user (“you”) and us. If you do not wish to be bound by this Terms then please do not use Stork Mobile Services, Website, or App.</p>
                    `,
                    },
                    clause02: {
                        heading: "Change of Terms",
                        html: `
                    <p class="ts-quiet">We may change the Terms from time to time. If we change the Terms, we will notify the Users (defined below) of such change by announcing the change on the website (<a class="ts-underline" href="https://storkmobile.com/">https://storkmobile.com/</a>) or such other method as may be separately specified by
us. After such notice, the Users shall be deemed to have consented to such change to the Terms by continuing using Stork Mobile Services.</p>
                    `,
                    },
                    clause03: {
                        heading: "Definitions",
                        html: `
                    <p class="ts-quiet counter">“Service(s)” or “Stork Mobile Service(s)” means the mobile internet communication service and all the related web applications provided by Us under Stork Mobile brand.</p>
                    <p class="ts-quiet counter">“User” means the individual who has opened an account with Stork Mobile and has or is about to use Stork Mobile mobile internet service with that account.</p>
                    <p class="ts-quiet counter">“eSIM” or “eSIM profile” in this Terms means a carrier profile for embedded Subscriber Identity Module (eSIM) that is conformed to the GSM Association standard.</p>
                    <p class="ts-quiet counter">“QR code” in this Terms means a QR code for Users to download and install an eSIM profile to their devices.</p>
                    <p class="ts-quiet counter">“Stork Mobile eSIM” means the eSIM offered by Us under Stork Mobile brand, that uses both roaming network and local mobile network for cellular data communication.</p>
                    <p class="ts-quiet counter">“Network Operator” or “Network Provider” in this Terms means each of the operator partners in whose networks the Stork Mobile SIM may be used by the User.</p>
                    <p class="ts-quiet counter">“Stork Mobile App” or “Stork Mobile Web App” means the proprietary software application offered by Us for Users for Stork Mobile Services.</p>
                    <p class="ts-quiet counter">“Top Up” or “Recharge” means the purchase of a Service by the User for additional data usage with the Stork Mobile eSIM.</p>
                    `,
                    },
                    clause04: {
                        heading: "Service Description",
                        html: `
                    <p class="ts-quiet">Stork Mobile eSIM only allows for cellular data service as defined by the Global System for Mobile Association (GSMA). All other services, including voice calls, SMS, and MMS cannot be made with the eSIM.</p>
                    `,
                    },
                    clause05: {
                        heading: "No Warranties",
                        html: `
                    <p class="ts-quiet counter">The User agrees that the Stork Mobile Service shall be provided on an “as-is” basis. To the extent permitted by law, we disclaim all representations and warranties, whether express or implied, with respect to the Stork Mobile Service including, without limitation, any warranties of quality, consistency of Service, non-mistake, non-infringement of third party’s right, fitness for any particular purpose.</p>
                    <p class="ts-quiet counter">You acknowledge that mobile internet service is a form of wireless communication and operates on the basis of transmission of radio and signal frequencies. As such it can be interfered with by numerous external sources or by obstacles inherent in buildings or terrain and as such we cannot guarantee a perfect or error-free transmission, both in terms of quality or availability of the Service. Also, please note that the network operator in the country of use will be responsible for network coverage, quality and availability.</p>
                    <p class="ts-quiet counter">Stork Mobile Service is not fault-tolerant and is not designed or intended for use as on-line control equipment in hazardous environments requiring fail-safe performance, examples such as in the operation of nuclear facilities, aircraft navigation or aircraft communication systems, public transportation, direct life support machines, or weapon’s systems, in which the failure of the Services could lead directly to death, personal injury, or severe physical or environmental damage (“high risk activities”). Accordingly, we disclaim any express or implied warranty of fitness for high risk activities. User agrees that we shall not be liable for any claims or damages arising from or related to the use of Stork Mobile Service in high risk activities or similar applications.</p>
                    <p class="ts-quiet counter">With respect to the data usage or data remaining displayed on Stork Mobile app, it is calculated based on the information we obtain from Network Provider(s) and we shall not bear any responsibility in relation to the accuracy of the amount of data at any given time.</p>
                    `,
                    },
                    clause06: {
                        heading: "User Restriction",
                        html: `
                    <p class="ts-quiet counter">If a natural disaster, incident or any other emergency situation occurs or is likely to occur, we may restrict the provision of the Service in order to give priority to communications whose content are necessary for the prevention of or relief from calamities, for the securing of transportation, communications or electric power supply, the maintenance of public order or any other public interest.</p>
                    <p class="ts-quiet counter">If we detect any significant and/or continuous signal or transmission using a communication procedure or application which occupies the communication bandwidth, we may control the transmission rate and/or traffic of such signal or transmission by controlling the communication bandwidth allocated to such signal or transmission.</p>
                    `,
                    },
                    clause07: {
                        heading: "Rights",
                        html: `
                    <p class="ts-quiet counter">We reserve the right to choose our network operators and technology partners as we deem appropriate and to provide access to the Service in any manner we deem in the best interest of our business requirements. In this regard you hereby consent to us disclosing and using any of your personal data when required for the provision of the Service. Any such use of your personal data will be subject to the terms of our Privacy Policy.</p>
                    <p class="ts-quiet counter">The User, by making his/her first online purchase or Top Up transaction for a Stork Mobile eSIM, consents and authorizes us to automatically debit the credit card (or other authorized payment method) used for the first purchase or Top Up by the User for any future data plan purchases or Top Up of the same eSIM.</p>
                    `,
                    },
                    clause08: {
                        heading: "Disconnection of Transmission",
                        html: `
                    <p class="ts-quiet">We may disconnect the session with respect to Stork Mobile Service transmissions if we deem excess data communication has been performed in the same session.</p>
                    `,
                    },
                    clause09: {
                        heading: "Suspension",
                        html: `
                    <p class="ts-quiet counter">From time to time we may be required to upgrade, modify or maintain the Service. On such occasions the Service may be temporarily unavailable, however, we will do our best to keep such disruption to a minimum and notify you in advance when possible, including by sending a message and/or posting on our website.</p>
                    <p class="ts-quiet counter">We reserve the right to suspend the Service without giving you notice where:</p>
                    <ul class="list-counter ts-quiet">
                        <li>we have reason to believe you are in breach of the Services conditions of usage or any of our policies notified to you on the Website.</li>
                        <li>you fail to provide us with the necessary information as required for satisfying a regulatory requirement in any jurisdiction you wish to travel to and use the Service.</li>
                        <li>we are obliged to comply with an order, instruction or request of any government, regulatory, or emergency services organization, or other competent administrative authority.</li>
                        <li>in order to prevent damage or degradation of our or our contracting party's network integrity which may be caused by you or anyone using your access.</li>
                        <li>for operational reasons or in an emergency or for security reasons.</li>
                        <li>a Network Provider suspends provision of telecommunication services to us.</li>
                        <li>a cloud service provider suspends provision of cloud services to us or to our Network Provider.</li>
                    </ul>
                    `,
                    },
                    clause10: {
                        heading: "Discontinuation of Service",
                        html: `
                    <p class="ts-quiet">If there are unavoidable reasons (including but not limited to technical reasons), we may discontinue provision of the Service in whole or partially.</p>
                    `,
                    },
                    clause11: {
                        heading: "Monitoring",
                        html: `
                    <p class="ts-quiet counter">We may at our discretion disconnect your eSIM if we have reasonable cause to suspect fraudulent use of a credit card/debit card, your eSIM card or mobile phone using the Service. We reserve the right to check your card transaction for our Service as well as to monitor your data usage with Stork Mobile eSIM.</p>
                    <p class="ts-quiet counter">We may monitor the Service and disclose information gained from such monitoring in order to satisfy any law, regulation or other governmental request, to operate and administer the Service, or to protect us or our other customers. The disclosure is conformed to our Privacy Policy.</p>
                    `,
                    },
                    clause12: {
                        heading: "Account",
                        html: `
                    <p class="ts-quiet counter">The User shall make an account that has an effective email address to use the Stork Mobile Service. The Subscriber may make only one Stork Mobile account per email address, unless otherwise expressly provided in the Terms.</p>
                    <p class="ts-quiet counter">The User shall manage and protect the Log-in Password at the User’s own responsibility, and shall not lend, transfer, or license it to any third party and shall not use the Log-in Password for the profit of any third party. The User shall notify us immediately in the cases where the User discovers the unauthorized use of the Stork Mobile Account by a third party or the risk of such unauthorized use, or where the User loses information regarding the his/her Account (including but not limited to the Log-in Password), or such information is stolen by third party.</p>
                    <p class="ts-quiet counter">Accounts with unconfirmed email addresses will be deleted in 30 days.</p>
                    <p class="ts-quiet counter">For the protection of the User and per our data retention compliance, we reserve the right to remove accounts that are inactive for over 18 months from their last activity.</p>
                    <p class="ts-quiet counter">The User is responsible for any event arising from the use of his/her own Stork Mobile Account. If we suffer any damage arising from the use of the Stork Mobile Account, the User must compensate us for such damage.</p>
                    <p class="ts-quiet counter">The User may request the deletion of their account at any time by submitting a request to: <a class="ts-underline" href="mailto:support@storkmobile.com">support@storkmobile.com</a>.</p>
                    `,
                    },
                    clause13: {
                        heading: "QR Code",
                        html: `
                    <p class="ts-quiet counter">A QR code can be used for one time only and the User cannot re-use it.</p>
                    <p class="ts-quiet counter">The User shall be fully responsible for the use and the management of the QR code and under no circumstances shall we be liable for any loss, expenses, or damages.</p>
                    <p class="ts-quiet counter">A QR code is valid for 180 days from the day it was provided, unless otherwise expressly provided in the Terms. Japan Standard Time applies.</p>
                    `,
                    },
                    clause14: {
                        heading: "eSIM Profile",
                        html: `
                    <p class="ts-quiet counter">The User shall be fully responsible for the use and the management of the eSIM and under no circumstances shall we be liable for any loss, expenses, or damages.</p>
                    <p class="ts-quiet counter">Stork Mobile eSIM is valid for 180 days from the day the last data plan or data top up was purchased for that eSIM, unless otherwise expressly provided in the Terms. Japan Standard Time applies.</p>
                    <p class="ts-quiet counter">Stork Mobile eSIM cannot be re-issued.</p>
                    <p class="ts-quiet counter">Stork Mobile eSIM registered to User’s account is non-transferable.</p>
                    `,
                    },
                    clause15: {
                        heading: "Compatibility",
                        html: `
                    <p class="ts-quiet counter">Stork Mobile  eSIM only works on unlocked devices with eSIM capability running a certain version of Operating System. For the full list of the compatibility devices and OS versions, please check our <a class="ts-underline" href="/compatibility/">Compatibility page</a>.  We shall not bear any responsibility in relation to the use of the incompatible devices.</p>
                    <p class="ts-quiet counter">It is required to set up the correct Access Point Name (APN) and the data roaming must be turned on in order to use Stork Mobile eSIM. We will not accept any liability for your failure to configure those settings.</p>
                    `,
                    },
                    clause16: {
                        heading: "Currency",
                        html: `
                    <p class="ts-quiet">The default transaction currency for Stork Mobile Services shall be US Dollars or the displayed currency at the point of sale.</p>
                    `,
                    },
                    clause17: {
                        heading: "Use of Service",
                        html: `
                    <p class="ts-quiet counter">The User shall use the Service lawfully and in compliance with this Terms and that you are responsible for all use of the Service including data charges incurred to you for using the Service.</p>
                    <p class="ts-quiet counter">The User shall be responsible for all use of the Services including but not limited to terminal devices, its proper configuration and installation, compliance with any regulations and applicable instructions issued either by us or the supplier of the terminal device. The preparation activities for using the Service, including but not limited to preparing and installing the eSIM, setting adequate protocols, as well as all relevant activities in support of the implementation of the Service shall remain the User’s responsibility.</p>
                    `,
                    },
                    clause18: {
                        heading: "Terminal Device",
                        html: `
                    <p class="ts-quiet ">The User shall be responsible for ensuring that your mobile device is compatible with the Services that you purchase from us. This may include ensuring that your handset is 'unlocked' and is compatible with the network type and the frequencies used in the country where you wish to use it. The quality of the Service is also dependent on the quality of the mobile device. The choice of mobile handset is solely yours.</p>
                    `,
                    },
                    clause19: {
                        heading: "Contents",
                        html: `
                    <p class="ts-quiet ">The User is solely responsible for any and all content, information and communications transmitted using the Service.</p>
                    `,
                    },
                    clause20: {
                        heading: "Regulatory Compliance",
                        html: `
                    <p class="ts-quiet counter">The User must only use the Services in accordance with all applicable laws and regulations of your home nation, the country in which you reside, or the country in which you are present whilst using the Service. We will not accept any liability for your failure to comply with those laws or regulations.</p>
                    <p class="ts-quiet counter">The use of the Services outside of Japan (roaming network) may be subject to different laws and regulations that apply. We will not accept any liability for your failure to comply with those laws or regulations.</p>
                    `,
                    },
                    clause21: {
                        heading: "Confidentiality",
                        html: `
                    <p class="ts-quiet ">The User shall keep all information confidential about the Stork Mobile’ technical, management and any other non-public information acquired by using Stork Mobile Service and shall not use such information except for the use of Stork Mobile Service; provided, however, that the User may disclose such information to the extent necessary to comply with any law or direction, regulation or order of a court, supervisory authority or any other public institution authorized to regulate the User.</p>
                    `,
                    },
                    clause22: {
                        heading: "Maintenance of Confidence",
                        html: `
                    <p class="ts-quiet ">In using the Stork Mobile Service, the User shall not act in any way to lose the confidence of Stork Mobile and ROKKODE.</p>
                    `,
                    },
                    clause23: {
                        heading: "",
                        html: `
                    <p class="ts-quiet">In addition to any other provision of the Terms, the User shall not:</p>
                    <ul class="list-counter ts-quiet">
                        <li>use the terminal device in any way that is not in compliance with the technical standards established by the applicable laws and regulations equivalent to the Federal Communications Commission (FCC) rules or the CE Marking (CE Mark) for Radio Telecommunications Equipment;</li>
                        <li>use the terminal device not supported by Stork Mobile Service;</li>
                        <li>perform any act prohibited by the telecommunication carrier(s);</li>
                        <li>perform any act prohibited by the applicable laws and regulations in the countries where the User uses Stork Mobile Service;</li>
                        <li>use the Stork Mobile Service for child prostitution, viewing or acquiring child pornography or sending nuisance email or any other purpose that we regard as inappropriate;</li>
                        <li>assign, transfer, sublet or provide as collateral, or otherwise dispose of all or part of Stork Mobile Information;</li>
                        <li>use Stork Mobile Service for any product or service that infringes on our intellectual property rights;</li>
                        <li>file application of intellectual property rights based on the Stork Mobile Service;</li>
                        <li>attack our web server by using unauthorized access or a computer virus;</li>
                        <li>reverse engineer, decompile, reverse assemble or otherwise analyze the Stork Mobile Service;</li>
                        <li>let a third party perform any of the respective acts of the preceding paragraph (j); or</li>
                        <li>transfer any data plan, data top-up or eSIM purchased by the User to third party.</li>
                    </ul>
                    `,
                    },
                    clause24: {
                        heading: "Cancellation Policy",
                        html: `
                    <p class="ts-quiet ">To cancel a data plan or a top-up after ordering and paying the pre-payment for it, you must inform us in writing, giving us your name, address and the order reference number. When the cancelation is accepted by us and it involves any amount of refund, we will process it within 30 days of the day you have given notice of your cancellation.</p>
                    `,
                    },
                    clause25: {
                        heading: "Refund Policy",
                        html: `
                    <p class="ts-quiet counter">We shall refund only when the eSIM Profile is used for the unlocked devices that are listed on our <a class="ts-underline" href="/compatibility">Compatibility page</a>.</p>
                    <p class="ts-quiet counter">We shall not refund for the eSIM Profile and the associated data plan when it was deleted manually by the user by mistake.</p>
                    <p class="ts-quiet counter">For data plans and top-ups that have been already used and is/was in use, we shall not, under any circumstances, be required to refund any data remaining (unused data) in any data plan or in any top-up purchased by the User. This shall not apply to the cases arising from our willful misconduct or gross negligence.</p>
                    `,
                    },
                    clause26: {
                        heading: "",
                        html: `
                    <p class="ts-quiet counter">If Stork Mobile Service and/or the accessorial services become unavailable due to any cause imputable to a third party (including but not limited to Network Provider(s) or cloud provider(s)), we shall not bear any responsibility in relation to such matter.</p>
                    <p class="ts-quiet counter">We shall not bear any responsibility for lost profits or costs arising out of the use of the Stork Mobile Service. The User shall not, under any circumstances, claim such lost profits or costs against us.</p>
                    <p class="ts-quiet counter">We do not and cannot control the quality of the Network Operator’s networks in which Stork Mobile eSIM may be used or interconnect with. Therefore, we disclaim any and all liability that may arise from the performance of the Network Operator’s networks.</p>
                    <p class="ts-quiet counter">In the case where the telecommunications facility or system is repaired or recovered, there is a possibility that information stored in the telecommunications facility or system may be altered or lost. We shall not bear any responsibility for damages arising from such alteration or loss, provided, that, this shall not apply to any damage arising from our willful misconduct or gross negligence.</p>
                    `,
                    },
                    clause27: {
                        heading: "Posting this Terms",
                        html: `
                    <p class="ts-quiet ">We will post the latest Terms of Use on Stork Mobile website (<a class="ts-underline" href="https://storkmobile.com">https://storkmobile.com</a>).</p>
                    `,
                    },
                    clause28: {
                        heading: "Governing Law and Legal Compliance",
                        html: `
                    <p class="ts-quiet counter">This Terms shall be governed by Japanese law and shall be subject to the jurisdiction of the Japanese Courts, Tokyo District Court being our first exclusive agreement jurisdiction court.</p>
                    <p class="ts-quiet counter">Despite the governing law provided above, you may be subject to various rules, regulations and laws which may also apply to you in the country in which you use the Services. You are required to obey those rules, regulations and laws.</p>
                    `,
                    },
                    clause29: {
                        heading: "Privacy Policy",
                        html: `
                    <p class="ts-quiet counter">We will specify the policy for processing Users’ personal information (the “Privacy Policy”), and post the Privacy Policy on Stork Mobile website.</p>
                    <p class="ts-quiet counter">Subject to the terms of our Privacy Policy we may be required to collect further personal information from you in order to satisfy our compliance with any legal or regulatory compliance requirement. If we request such information from you, you agree that you shall provide us with the requested information and not provide us with false or misleading information.</p>
                    `,
                    },
                    clause30: {
                        heading: "Intellectual Property",
                        html: `
                    <p class="ts-quiet ">All intellectual property rights in Stork Mobile Services belong to Us. The purchase or use of such Stork Mobile Services by the User does not imply any transfer of intellectual property rights from Us to the User.</p>
                    `,
                    },
                    clause31: {
                        heading: "Terminating and rejecting relations with Anti-social Forces",
                        html: `
                    <p class="ts-quiet ">The User acknowledges and agrees that, since ROKKODE affiliates are Japanese companies, we must comply with the relevant rules, regulations and standards which purport to eliminate any relationship with “Anti-Social Forces” (meaning violent gangs, yakuza, mafia or other violent, blackmailing, or intimidatory groups or people who for political, religious or other ideological or economic purposes engage in anti-social activities or behavior including, but not limited to “Organized Crime Groups” or “Organized Crime Group Members” (as respectively defined in Article 2, items (ii) and (vi) of the Prevention of Unjust Acts by Organized Crime Group Members Act of Japan. The same shall apply hereafter.). As a part of such compliance, the User shall ensure that at all times it and its officers, employees and any shareholders in the User;</p>
                    <p class="ts-quiet counter">do not and will not have any relationship or connection with any Anti-Social Forces;</p>
                    <p class="ts-quiet counter">are not and will not become involved in any financial dealings with or provide any financial assistance to any Anti-Social Forces for any reason whatsoever; and/or</p>
                    <p class="ts-quiet counter">do not and will not employ, hire or otherwise engage any person who is a member of any Anti-Social Force or appoint such person to any directorship or officer position.</p>
                    `,
                    },
                    clause32: {
                        heading: "Severability",
                        html: `
                    <p class="ts-quiet ">If any provision in this Terms is found to be invalid or unenforceable, then the meaning of such provision shall be construed, to the extent feasible, so as to render the provision enforceable, and if not feasible.</p>
                    `,
                    },
                    clause33: {
                        heading: "Marketing",
                        html: `
                    <p class="ts-quiet counter">We may from time to time send you information about our other products and service updates. If you do not want to receive information about our other products and service updates you should contact us in writing at <a class="ts-underline" href="mailto:support@storkmobile.com">support@storkmobile.com</a>.</p>
                    <p class="ts-quiet counter">We may compile and release information regarding you, as one of our users,  and your use of the Service on an anonymous basis as part of a customer profile or similar report or analysis.</p>
                    `,
                    },
                },
            },
            ja: {
                title: "利用規約",
                category: {
                    "01": "総則",
                    "02": "サービス",
                    "03": "責務等",
                    "04": "禁止事項",
                    "05": "キャンセルおよび返金",
                    "06": "責任の制限",
                    "07": "雑則",
                },
                clauses: {
                    clause01: {
                        heading: "規約の適用",
                        html: `
                    <p class="ts-quiet">ROKKODE 株式会社（以下「当社」という。）は以下に定める利用規約（以下「本規約」という。）に基づきStork Mobile ブランドでのデータ通信サービスおよびそれに付随するすべてのオンラインサービスを提供します。当社が提供する Stork Mobile サービスを購入・利用することにより、利用者は本規約に同意したものとします。</p>
                    `,
                    },
                    clause02: {
                        heading: "規約の変更",
                        html: `
                    <p class="ts-quiet">当社は、本規約を変更することがあります。かかる変更を実施する場合、当社は、ウェブサイト（<a class="ts-underline" href="https://storkmobile.com/ja/">https://storkmobile.com/ja/</a>）への掲示又は当社が別途定める方法でユーザー（後項に規定）に対して変更内容を告知するものとします。当該告知が行なわれた後にユーザーが引き続きStork Mobile サービスを利用する場合には、ユーザーはかかる変更に同意したものとみなします。</p>
                    `,
                    },
                    clause03: {
                        heading: "用語の定義",
                        html: `
                    <p class="ts-quiet counter">「サービス」または「Stork Mobile サービス」とは当社が Stork Mobile ブランドで提供するモバイルデータ通信サービスおよび通信に必要なデータ、あるいはそれらに付随するすべてのオンラインサービスです。</p>
                    <p class="ts-quiet counter">「ユーザー」とはStork Mobileのアカウントを作成し、Stork Mobileが提供するモバイルデータ通信サービスを使った、あるいは使う予定の個人です。</p>
                    <p class="ts-quiet counter">本規約において「eSIM」または「eSIMプロファイル」とはGSMA準拠のリモートSIMプロビジョニングに対応した、端末組込みSIM用通信事業者プロファイルを指します。</p>
                    <p class="ts-quiet counter">本規約において「QRコード」とはユーザーが端末へeSIMプロファイルをダウンロードし、インストールする目的で当社が提供するQRコードを指します。</p>
                    <p class="ts-quiet counter">「Stork Mobile eSIM」とは当社がStork Mobileブランドで提供するローミング回線あるいはローカルセルラー回線を利用するeSIMです。</p>
                    <p class="ts-quiet counter">本規約において「通信事業者」とはStork Mobile eSIMが利用する各国の移動体通信事業者を指します。</p>
                    <p class="ts-quiet counter">「Stork Mobile アプリ」または「Webアプリ」とは当社がStork Mobile サービスを利用するユーザーのために提供するソフトウェアアプリケーションです。</p>
                    <p class="ts-quiet counter">本規約において「データチャージ」あるいは単に「チャージ」とはStork Mobile eSIMで利用するための追加のデータ容量を購入する行為を指します。</p>
                    `,
                    },
                    clause04: {
                        heading: "サービスの概要",
                        html: `
                    <p class="ts-quiet">Stork Mobile eSIMはセルラー回線を利用したモバイルデータ通信専用であり、電話番号を用いた通話やSMS、MMSなどそのほかのサービスは利用できません。</p>
                    `,
                    },
                    clause05: {
                        heading: "保証の否認",
                        html: `
                    <p class="ts-quiet counter">ユーザーは、Stork Mobile サービスは現状のままで提供されることに合意するものとします。当社は提供される Stork Mobile サービスに関し、品質、サービスが中断されないこと、誤りがないこと、第三者の権利を侵害しないこと、特定目的への適合性の保証を含め、明示であると黙示であるとを問わず、いかなる種類の表明も保証も行いません。</p>
                    <p class="ts-quiet counter">モバイルデータ通信サービスは電波の伝送により成り立つ無線通信技術を利用するため、建物や地形による障害物を含む様々な外的要因に影響される性質を有します。そのため当社は、提供するサービスの品質および利用可能範囲に関して一切の保証を行いません。また、サービスを使用する国におけるネットワークカバレッジ（利用可能範囲）や通信品質はその国の通信事業者が責任を負うものとします。</p>
                    <p class="ts-quiet counter">Stork Mobile サービスは、障害耐性を保証するものではなく、サービスの停止が直接人的損傷や死、または物理的あるいは環境的ダメージに直結するような、核施設、航空機ナビゲーションや航空通信システム、公共交通機関、延命装置、兵器システムといったフェイルセーフ性能を要求される危険環境下における通信用には設計されていません。当社はそれら高危険度活動におけるStork Mobile サービスの使用に対して明示黙示を問わず、いかなる種類の表明も保証も行いません。また、ユーザーは当社がそのような高危険度活動におけるStork Mobile サービスの使用に起因するいかなる損害に対しても一切責任を負わないことに同意するものとします。</p>
                    <p class="ts-quiet counter">Stork Mobile アプリ上で表示されるデータ使用量またはデータ残量は当社が通信事業者から取得した情報を元に計算されるもので、それらデータ量の精度は当社が保証するものではない。</p>
                    `,
                    },
                    clause06: {
                        heading: "利用の制限",
                        html: `
                    <p class="ts-quiet counter">当社は、天災、事変その他の非常事態が発生し、又は発生するおそれがあるときは、災害の予防もしは救援、交通、通信若しくは電力の供給の確保、又は秩序の維持その他の公共の利益のために必要となる通信を優先的に取り扱うため、サービスの利用を制限することができます。</p>
                    <p class="ts-quiet counter">当社は、帯域を継続的かつ大量に占有する通信手順又はアプリケーションを用いて行われる電気通信を検知し、その電気通信に割り当てられる帯域を制御すること等により、その電気通信の速度や通信量を制御することができます。</p>
                    `,
                    },
                    clause07: {
                        heading: "権利",
                        html: `
                    <p class="ts-quiet counter">当社は継続的なサービス提供を目指した事業運営上の都合により提携する通信事業者や使用する技術について変更が必要と判断される場合はいつ何時でもその変更を行う権利を持ちます。また、それに関して必要な場合には当社プライバシーポリシーに基づきユーザーのデータを利用することをユーザーは承諾するものとします。</p>
                    <p class="ts-quiet counter">ユーザーはオンラインからの初回の購入あるいはデータチャージを行うことにより、同じeSIMへの以後のデータプラン購入やデータチャージに関して当社が所定のクレジットカードや許可された支払い方法を用いて請求を行うことに同意し、許可することとします。</p>
                    `,
                    },
                    clause08: {
                        heading: "通信の切断",
                        html: `
                    <p class="ts-quiet">当社は、ユーザーのStork Mobile サービス利用に関して、同一セッション内に大量の通信があったと当社が認める場合において、その通信を切断することがあります。</p>
                    `,
                    },
                    clause09: {
                        heading: "提供中止",
                        html: `
                    <p class="ts-quiet counter">サービスの改良等のため時と場合により当社はStork Mobile サービスを中断する場合があることをユーザーは承諾するものとします。そのような場合でも、当社は中断時間を最小限に抑える努力をすることを約束するとともに、できる場合は通知を送るなりウェブサイトに記載するなどして事前周知を行います。</p>
                    <p class="ts-quiet counter">当社は、次の場合にはStork Mobileサービスの提供を中止することができます。</p>
                    <ul class="list-counter ts-quiet">
                        <li>ユーザーが当社のサービス利用上の規約やその他ウェブサイトに記載するポリシーに違反していると判断できる正当な事由がある場合</li>
                        <li>サービスを利用する、あるいは利用予定の国の管轄局から要求される情報の提供を怠った場合</li>
                        <li>各国政府、管轄局または関係する政府機関による命令や指示、依頼があった場合</li>
                        <li>当社又は通信事業者のサービスへの損害や品質劣化を防ぐためやむを得ないとき</li>
                        <li>当社の事業運営上の都合、緊急事態又は安全保障上やむを得ないとき</li>
                        <li>通信事業者が当社への通信サービスの提供を停止したとき</li>
                        <li>クラウドサービス提供業者が当社又は通信事業者へのクラウドサービスの提供を停止したとき</li>
                    </ul>
                    `,
                    },
                    clause10: {
                        heading: "サービスの廃止",
                        html: `
                    <p class="ts-quiet">当社は、技術上及び業務の遂行上やむを得ない場合は、Stork Mobile サービスの全部又は一部を廃止することがあります。</p>
                    `,
                    },
                    clause11: {
                        heading: "監視",
                        html: `
                    <p class="ts-quiet counter">ユーザーのクレジットカードやデビットカードの不正利用や、eSIMの不正利用を疑う十分な証拠がある場合は当社の判断でユーザーのeSIMへのサービス提供を停止することがある。また、その判断のためにユーザーのカード利用の詳細確認やデータ利用の監視を行います。</p>
                    <p class="ts-quiet counter">法律や規制、あるいは行政機関からの依頼があった場合には、当社はユーザーのサービス利用の監視により得られた情報を開示します。尚、開示行為は当社のプライバシーポリシーに準じて行われます。</p>
                    `,
                    },
                    clause12: {
                        heading: "アカウント",
                        html: `
                    <p class="ts-quiet counter">Stork Mobile サービスを利用するためには、ユーザーは有効な電子メールアドレスに関連づけたアカウントを作成しなければなりません。本規約で明示的に認められている場合を除き、ユーザーは一つの電子メールアドレスにつき、一つのアカウントのみ作成することができます。</p>
                    <p class="ts-quiet counter">ユーザーは自己の責任の元、ログインパスワードを管理するものとし、ログインパスワードを第三者に貸与、譲渡もしくは使用許諾又は第三者の利益のために使用してはならないものとします。また、ユーザーは、アカウントの不正使用もしくはそのおそれを認識した場合又はアカウント情報の紛失若しくは盗難があった場合、直ちに当社にその旨通知するものとします。</p>
                    <p class="ts-quiet counter">当社が指示するユーザーによるメールアドレスの確認行為が行われなかったアカウントについては30日経過後に削除します。</p>
                    <p class="ts-quiet counter">当社は最終のアクティビティーから18ヶ月間一切ログインおよび使用されなかったアカウントについてはそれらを削除する権利を有します。</p>
                    <p class="ts-quiet counter">ユーザーは自らのアカウントに生じるあらゆる事象につき、一切の責任を負うものとし、当社は何らの責任も負担しないものとします。また、かかるアカウントの使用に基づき万が一当社に損害が発生した場合、契約者は当社に対し、当該損害を賠償しなければならないものとします。</p>
                    `,
                    },
                    clause13: {
                        heading: "QRコード",
                        html: `
                    <p class="ts-quiet counter">eSIMプロファイルダウンロード用QRコードは一回のみ使用できるもので、再利用はできません。</p>
                    <p class="ts-quiet counter">当社は、ユーザーが当社から取得したeSIMプロファイルダウンロード用QRコードの保管・管理、使用において一切の責任を負いません。</p>
                    <p class="ts-quiet counter">QRコードの有効期限は本規約で明示的に認められている場合を除き発行日より180日とします。基準時間は日本標準時間とします。</p>
                    `,
                    },
                    clause14: {
                        heading: "eSIMプロファイル",
                        html: `
                    <p class="ts-quiet counter">当社は、ユーザーが当社から取得したeSIMの保管・管理、使用において一切の責任を負いません。</p>
                    <p class="ts-quiet counter">Stork Mobile eSIMの有効期限は本規約で明示的に認められている場合を除き、直近のデータプラン購入日またはデータの追加購入日より180日とします。基準時間は日本標準時間とします。</p>
                    <p class="ts-quiet counter">Stork Mobile eSIMの再発行はできません。</p>
                    <p class="ts-quiet counter">ユーザーのアカウントに登録されているStork Mobile eSIMは譲渡できません。</p>
                    `,
                    },
                    clause15: {
                        heading: "動作保障環境",
                        html: `
                    <p class="ts-quiet counter">Stork Mobile eSIMは組込み型SIM（eUICC）を搭載したSIMロックフリー（SIMロックのかかっていない）端末で、一定のOSバージョン環境でのみ使用できます。利用可能端末についての詳しい情報はウェブサイトの<a class="ts-underline" href="/ja/compatibility/">eSIM搭載端末ページ</a>をご確認ください。このページに記載している端末やOSバージョン以外でStork Mobile eSIMを使用した場合に関しては一切動作保証はせず、また発生する損害に対しての責任を負いません。</p>
                    <p class="ts-quiet counter">使用時には適切なアクセスポイント名の設定と、データローミング設定が必要です。当社はユーザーがそれらの設定を怠ったことに起因するいかなる責任も負いません。</p>
                    `,
                    },
                    clause16: {
                        heading: "通貨",
                        html: `
                    <p class="ts-quiet">Stork Mobile サービスへの支払いに使用する通貨は別途表記がない限り、アメリカドルとする。</p>
                    `,
                    },
                    clause17: {
                        heading: "サービスの使用",
                        html: `
                    <p class="ts-quiet counter">ユーザーは本規約に基づき合法的にサービスを利用するものとし、使用データ費用を含むあらゆるサービス利用に責任を負うものとします。</p>
                    <p class="ts-quiet counter">ユーザーは端末とその設定を含むあらゆるサービス利用に責任を負うものとし、当社や端末製造メーカーの指示やその他法令に従って端末をサービス利用に使用するものとします。また、eSIMの準備、インストール、適切な設定その他サービス利用に必要な関係する行為を含むサービス利用の準備もユーザーの責任の範囲内とします。</p>
                    `,
                    },
                    clause18: {
                        heading: "端末",
                        html: `
                    <p class="ts-quiet ">ユーザーは端末がSIMロックフリーであること、また、Stork Mobile サービスの使用を希望する国の通信方式および周波数に適合していることを含め、端末がStork Mobile サービスを利用できることを確認する責任を有します。尚、サービスの品質も使用する端末に影響されることを理解し、端末を選定することとします。</p>
                    `,
                    },
                    clause19: {
                        heading: "コンテンツ",
                        html: `
                    <p class="ts-quiet ">ユーザーはサービスを利用して送受信されるあらゆるコンテンツや情報に対して完全に責任を負うこととします。</p>
                    `,
                    },
                    clause20: {
                        heading: "法規制の順守",
                        html: `
                    <p class="ts-quiet counter">ユーザーは自国、居住国、あるいはサービスを利用する国における全ての適用法に従ってサービスを利用しなければなりません。当社はユーザーがそれら適用法の尊守を怠ったことに起因するいかなる責任も負いません。</p>
                    <p class="ts-quiet counter">日本国外で使用するサービス（ローミングネットワーク）についてはその国々で異なる法律や規制が適用される場合があります。ユーザーはそれらの法律や規制を事前に把握・理解し、従わなければなりません。当社はユーザーがそれを怠った場合に発生する損害等に関して一切責任を負いません。</p>
                    `,
                    },
                    clause21: {
                        heading: "守秘義務",
                        html: `
                    <p class="ts-quiet ">ユーザーはStork Mobile サービスに関する技術上、経営上及びその他一般に公表していない一切の情報に関する秘密を取得した場合はそれを厳守し、サービス使用の目的以外に使用しないこととします。ただし、法令又は裁判所、監督官庁その他公的機関の裁判、規則又は命令に従い必要な範囲においては当該情報を開示することができます。</p>
                    `,
                    },
                    clause22: {
                        heading: "信用の維持",
                        html: `
                    <p class="ts-quiet ">ユーザーはStork Mobile サービスの使用にあたり、当社の信用を損なう行為を行わないように努めるものとします。</p>
                    `,
                    },
                    clause23: {
                        heading: "",
                        html: `
                    <p class="ts-quiet">本規約の他の規定において定めるものに加え、ユーザーは、次の各号に掲げる行為を行うことはできません。</p>
                    <ul class="list-counter ts-quiet">
                        <li>連邦通信委員会の規定またはCEマークと同等またはそれらに相当する電気通信事業法及び電波法等の関係法令が定める技術基準に適合しない端末を利用すること</li>
                        <li>動作保証端末以外の端末を利用すること</li>
                        <li>通信事業者により禁止されている行為</li>
                        <li>ユーザーがStork Mobileサービスを利用する国の電波法等の関係法令が禁止する行為</li>
                        <li>児童買春、児童ポルノを閲覧又は取得するため、迷惑メール又は SMS 等の送信その他当社が不適切と判断する目的のためにStork Mobile サービスを利用する行為</li>
                        <li>Stork Mobile サービスの利用資格を含む情報の全部若しくは一部の第三者への譲渡、承継、転貸、質権その他担保に供する等の行為</li>
                        <li>当社の知的財産権を侵害する商品又はサービスに対して Stork Mobile サービスを利用すること</li>
                        <li>Stork Mobile サービスの利用を基にして知的財産権を出願すること</li>
                        <li>不正なアクセス、コンピューターウィルス等を用いて当社の管理するサーバーに対して攻撃を行うこと</li>
                        <li>Stork Mobile サービスに対し、リバースエンジニアリング、逆コンパイル、逆アセンブルその他一切の解析を行うこと</li>
                        <li>前各号の行為を第三者に行わせること</li>
                        <li>ユーザーが購入したデータプランやデータチャージ、取得したeSIMを第三者へ譲渡すること</li>
                    </ul>
                    `,
                    },
                    clause24: {
                        heading: "キャンセルポリシー",
                        html: `
                    <p class="ts-quiet ">支払い完了後にデータプランまたはデータチャージのキャンセルを希望する場合、ユーザーは必ず名前、メールアドレス、注文番号の情報を添えて書面で当社に連絡するものとします。当社がそのキャンセル依頼を許諾し、且つ支払い金額の一部または全部の返金が伴う場合は、当社はそのキャンセル依頼を書面で受け取った日から30日以内に必要な返金処理を行います。</p>
                    `,
                    },
                    clause25: {
                        heading: "返金ポリシー",
                        html: `
                    <p class="ts-quiet counter">当社は、eSIMが<a class="ts-underline" href="/ja/compatibility">動作保証端末ページ</a>に記載のあるSIMフリー端末に使われたことが確認できる場合のみ返金を行います。</p>
                    <p class="ts-quiet counter">当社は、ユーザーの過失によって削除されたeSIMプロファイルおよびそれに付属するデータプランについては、いかなる返金にも応じません。</p>
                    <p class="ts-quiet counter">当社は、既に使用されたデータプランやデータチャージについては、当社の故意又は重大な過失に起因する場合を除き、いかなる返金にも応じません。</p>
                    `,
                    },
                    clause26: {
                        heading: "",
                        html: `
                    <p class="ts-quiet counter">当社は、第三者の責めに帰すべき事由によって Stork Mobile サービス、及び付帯するサービスが利用不能となった場合、責任を負わないものとします。</p>
                    <p class="ts-quiet counter">当社は、Stork Mobile サービスの提供が行われなかったことによる逸失利益について一切責任を負わないものとし、ユーザーはかかる逸失利益又は費用等を当社へ請求しないものとします。</p>
                    <p class="ts-quiet counter">当社は、Stork Mobile eSIMが利用する通信事業者のネットワークの品質において一切関与せず、また関与できず、従って、通信事業者のネットワークの品質に起因する一切の責任を負わないものとします。</p>
                    <p class="ts-quiet counter">電気通信設備又はシステムの修理、復旧等に当たって、その電気通信設備又はシステムに記憶されている内容等が変化又は消失することがあります。当社はこれにより損害を与えた場合に、それが当社の故意又は重大な過失により生じたものであるときを除き、その損害を賠償しません。</p>
                    `,
                    },
                    clause27: {
                        heading: "約款の掲示",
                        html: `
                    <p class="ts-quiet ">当社は、最新の本規約を当社のウェブサイト（<a class="ts-underline" href="https://storkmobile.com/ja/">https://storkmobile.com/ja/</a>）において掲示することとします。</p>
                    `,
                    },
                    clause28: {
                        heading: "準拠法および法令順守",
                        html: `
                    <p class="ts-quiet counter">本約款の成立、効力、解釈及び履行については、日本国法に準拠するものとします。また、本契約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
                    <p class="ts-quiet counter">前項の準拠法以外にも、サービスを利用する国によりユーザーに様々な法令が適用される場合があります。ユーザーは各国の法令に従いStork Mobileサービスを利用することとします。</p>
                    `,
                    },
                    clause29: {
                        heading: "プライバシーポリシー",
                        html: `
                    <p class="ts-quiet counter">当社は、ユーザーに関する個人情報の取扱いに関する方針（以下「プライバシーポリシー」という。）を定め、これをサイトにて公開します。</p>
                    <p class="ts-quiet counter">プライバシーポリシーに規定された条項により、法令準拠の目的のためユーザーから追加の個人情報を収集する必要がある場合があります。その場合ユーザーは嘘偽りのない情報を当社に提供するものとします。</p>
                    `,
                    },
                    clause30: {
                        heading: "知的財産権",
                        html: `
                    <p class="ts-quiet ">Stork Mobile サービスに付帯するサービスに関する特許権、実用新案権、意匠権、著作権、ノウハウその他一切の知的財産は当社に帰属するものであり、本規約、Stork Mobile サービスはこれらに付帯するサービス提供の過程での当社によるユーザーに対する情報の開示は、明示、黙示を問わず、いかなる意味においても、当社の特許権、実用新案権、意匠権、著作権、ノウハウその他一切の知的財産に基づく実施権その他のいかなる権利の許諾、付与、又は譲渡を構成するものではありません。</p>
                    `,
                    },
                    clause31: {
                        heading: "反社会的勢力の排除",
                        html: `
                    <p class="ts-quiet counter">当社及びユーザーは、自己が反社会的勢力（｢企業が反社会的勢力による被害を防止するための指針（平成19年6月19日犯罪対策閣僚会議幹事会申合せ）｣において、暴力、威力又は詐欺的手法を駆使して経済的利益を追求する集団又は個人である旨定められている｢反社会的勢力｣、以下同じとします。）又は次のいずれかに該当する者（以下併せて｢反社会的勢力等｣という。）に該当しないことを表明及び保証し、現在及び将来において反社会的勢力又は次の事項に該当しないことを確約するものとします。</p>
                    <ul class="list-counter ts-quiet">
                        <li>役員等（役員のほか、支配人、営業所の代表者その他いかなる名称によるかを問わず役員と同等以上の職権又は支配力を有するものをいい、非常勤の者を含みます。）に、暴力団員による不当な行為の防止等に関する法律（平成3年法律第77号。その後の改正を含みます。）第2条第6号に規定する暴力団員（以下｢暴力団員｣という。）又は同条第2号に規定する暴力団（以下｢暴力団｣という。）と関係を持ちながら、その組織の威力を背景として同条第1号に規定する暴力的不法行為等を行なうおそれがある者（以下｢暴力団関係者｣という。）がいること。</li>
                        <li>暴力団、暴力団員又は暴力団関係者（以下これら三者を｢暴力団等｣と総称する。）が経営に関与していること。</li>
                        <li>暴力団等から名目を問わず資金提供、出資などの便益を受けていること。</li>
                        <li>暴力団等に対し名目を問わず資金の供給などの便益を供与していること。</li>
                        <li>反社会的勢力との間に、利用、協力、交際など社会的に非難されるべき関係を有していること。</li>
                    </ul>
                    <p class="ts-quiet counter">当社又はユーザーが、相手方が第1項の規定に反すると疑う事実のあるときは、相手方に対し当該事項に関する報告を求めることができ、報告を求められた相手方は指定された期日までに報告書を提出するものとします。</p>
                    <p class="ts-quiet counter">当社又はユーザーは、相手方が次の各号のいずれかに該当した場合は、即時本契約を解除し、解除によって生じた損害を相手方に請求することができるものとします。</p>
                    <ul class="list-counter ts-quiet">
                        <li>第1項の表明、保証又は確約に反し、又は反すると疑うに足る相当の理由があるとき。</li>
                        <li>第2項の規定に違反して報告書を提出せず、又は虚偽の記載をした報告書を提出したとき。</li>
                    </ul>
                    `,
                    },
                    clause32: {
                        heading: "分離可能性",
                        html: `
                    <p class="ts-quiet ">本規約のいずれかの条項が何らかの理由により無効又は執行不能とされた場合であっても、本規約の他の条項が無効又は執行不能となるものではなく、また、かかる場合には、当該規定は、有効かつ執行可能となるために必要な限度において限定的に解釈されるものとします。</p>
                    `,
                    },
                    clause33: {
                        heading: "マーケティング",
                        html: `
                    <p class="ts-quiet counter">当社からユーザーに新商品やサービスのアップデート等の情報を送信する場合があります。もしこれらの情報やアップデートを受け取りたくない場合は <a class="ts-underline" href="mailto:support@storkmobile.com">support@storkmobile.com</a> までその旨をご連絡ください。</p>
                    <p class="ts-quiet counter">当社は顧客プロファイルや顧客解析、またはそれらに関連するレポートの一部として、ユーザーやユーザーのサービス利用に関する情報を匿名性を保つ形で利用することがあります。</p>
                    `,
                    },
                },
            },
        },
    },
}
</script>
